import { defineSlotRecipe } from '@chakra-ui/react';
import { badgeRecipe } from 'components/chakra/badge/design-system/badge.recipe';
import { tagAnatomy } from '@chakra-ui/react/anatomy';

const badgeVariant = badgeRecipe.variants?.variant;

export const tagSlotRecipe = defineSlotRecipe({
  slots: tagAnatomy.keys(),
  className: 'chakra-tag',
  base: {
    root: {
      display: 'inline-flex',
      alignItems: 'center',
      verticalAlign: 'top',
      maxWidth: '100%',
      rounded: 'xs',
      userSelect: 'none',
      focusVisibleRing: 'outside'
    },
    label: {
      lineClamp: '1'
    },
    closeTrigger: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      outline: '0',
      borderRadius: 'l1',
      color: 'currentColor',
      focusVisibleRing: 'inside',
      focusRingWidth: '2px'
    },
    // @ts-ignore
    startElement: {
      flexShrink: 0,
      boxSize: 'var(--tag-element-size)',
      ms: 'var(--tag-element-offset)',
      '&:has([data-scope=avatar])': {
        boxSize: 'var(--tag-avatar-size)',
        ms: 'calc(var(--tag-element-offset) * 1.5)'
      },
      _icon: { boxSize: '100%' }
    },
    // @ts-ignore
    endElement: {
      flexShrink: 0,
      boxSize: 'var(--tag-element-size)',
      me: 'var(--tag-element-offset)',
      _icon: { boxSize: '100%' },
      '&:has(button)': {
        ms: 'calc(var(--tag-element-offset) * -1)'
      }
    }
  },

  variants: {
    size: {
      md: {
        root: {
          px: '2',
          minH: '5',
          gap: '1',
          '--tag-avatar-size': 'spacing.3.5',
          '--tag-element-size': 'spacing.3.5',
          '--tag-element-offset': '-2px'
        },
        label: {
          fontWeight: 'semibold',
          fontSize: '12px',
          lineHeight: '14px'
        }
      }
    },

    variant: {
      solid: {
        root: badgeVariant?.solid
      }
    }
  },

  defaultVariants: {
    size: 'md',
    variant: 'solid'
  }
});

import { Box, chakra, Flex, Span, Text } from '@chakra-ui/react';
import { useState } from 'react';
import { RecordListTable } from 'components/record-list-screen/table';
import Icon, { ICONS } from 'shared/components/icon';
import RedBtnActionMenu from 'components/chakra/menu/patterns/red-btn-action-menu';
import { useDialog } from 'hooks/use-dialog';
import InterestLevelCard from 'features/buyer-mode/components/interest-level-card';
import PipelineCard from 'features/buyer-mode/components/buyer-pipeline-card';
import { ActionMenu } from 'components/chakra/menu/patterns/action-menu';
import { Selection } from 'components/record-list-screen/types';
import AddFeedbackCell from './add-feedback-cell';

const mockData = [
  {
    name: 'John Doe',
    interest_level: 'Hot',
    email: 'john.doe@example.com'
  },
  {
    name: 'Jane Doe',
    interest_level: 'Warm',
    email: 'jane.doe@example.com'
  },
  {
    name: 'John Doe',
    interest_level: 'Hot',
    email: 'john.doe@example.com'
  },
  {
    name: 'Jane Doe',
    interest_level: 'Warm',
    email: 'jane.doe@example.com'
  },
  {
    name: 'John Doe',
    interest_level: 'Hot',
    email: 'john.doe@example.com'
  },
  {
    name: 'Jane Doe',
    interest_level: 'Warm',
    email: 'jane.doe@example.com'
  }
];

const columns = [
  {
    id: 'person',
    label: 'Person',
    selector: (row) => row.name
  },
  {
    id: 'interest_level',
    label: 'Interest',
    selector: (row) => row.interest_level
  },
  {
    id: 'enquiries',
    label: 'Enquiries',
    selector: (row) => row.email
  },
  {
    id: 'feedback',
    label: '',
    Cell: AddFeedbackCell,
    forced: true
  }
];

const mockInterestData = [
  {
    id: 'hot',
    label: 'Hot buyers',
    count: 234,
    icon: ICONS.BUILDING,
    color: 'red.500'
  },
  {
    id: 'warm',
    label: 'Warm buyers',
    count: 99,
    icon: ICONS.BUILDING,
    color: 'yellow.500'
  },
  {
    id: 'cold',
    label: 'Cold buyers',
    count: 233,
    icon: ICONS.BUILDING,
    color: 'blue.900'
  },
  {
    id: 'total',
    label: 'Total buyers',
    count: 73,
    icon: ICONS.BUILDING,
    color: 'green.500'
  }
];

const mockPipelineData = [
  {
    id: 'enquiries',
    label: 'Enquiries',
    count: 20
  },
  {
    id: 'upcoming-attendees',
    label: 'Upcoming attendees',
    count: 89
  },
  {
    id: 'past-attendees',
    label: 'Past attendees',
    count: 55
  },
  {
    id: 'contract-sent',
    label: 'Contract sent',
    count: 76
  },
  {
    id: 'offers',
    label: 'Offers',
    count: 234
  }
];

const datePresets = [
  {
    id: '1',
    type: 'Open Home',
    value: '22 August 2024, 3PM - 4PM'
  },
  {
    id: '2',
    type: 'Private Inspection',
    value: '22 August 2024, 3PM - 4PM'
  }
];

function InspectionFollowUpTab() {
  const contactDrawer = useDialog('contactDrawer');
  const [visibleColumns, setVisibleColumns] = useState<string[]>(
    columns.map((column) => column.id)
  );
  const [selection, setSelection] = useState<Selection>({
    type: 'include',
    ids: []
  });
  const [inspectionType, setInspectionType] = useState(datePresets[0]);

  return (
    <Flex direction={'column'}>
      <Box p={4} bg={'grey.50'}>
        <Text textStyle={'body'} mb={1}>
          {inspectionType.type}
        </Text>
        <ActionMenu
          items={datePresets.map((preset) => ({
            id: preset.id,
            label: (
              <Text fontSize={'17px'}>
                {preset.type} <Span fontWeight={600}>{preset.value}</Span>
              </Text>
            ),
            onClick: () => {
              setInspectionType(preset);
            }
          }))}
        >
          <chakra.button
            display={'flex'}
            alignItems={'center'}
            fontSize={'22px'}
            cursor={'pointer'}
          >
            <Span fontSize={'22px'} fontWeight={600} mr={1}>
              22 August 2024
            </Span>
            3:00PM - 3:30PM
            <Icon
              type={ICONS.ARROW_DOWN}
              width={30}
              height={30}
              style={{ transform: 'rotate(-90deg)' }}
            />
          </chakra.button>
        </ActionMenu>
        <Flex direction={'row'} alignItems={'center'} my={4} gap={4}>
          {mockInterestData.map((item, index) => (
            <InterestLevelCard
              key={item.id}
              isSelected={index === 0}
              onSelect={() => null}
              {...item}
            />
          ))}
        </Flex>
        <Flex
          direction={'row'}
          alignItems={'center'}
          justifyContent={'space-between'}
        >
          {mockPipelineData.map((item, index) => (
            <PipelineCard
              key={item.id}
              isSelected={index === 0}
              onSelect={() => null}
              {...item}
            />
          ))}
        </Flex>
      </Box>
      <Box p={4}>
        <Flex alignItems={'center'} gap={2} mb={4}>
          <Text textStyle={'h3'}>Attendees List</Text>
          <RedBtnActionMenu
            Icon={<Icon type={ICONS.ADD} width={10} height={10} />}
            items={[{ label: 'Edit', id: 'edit' }]}
          >
            Add Attendee
          </RedBtnActionMenu>
        </Flex>
        <RecordListTable
          columns={columns}
          items={mockData}
          isLoading={false}
          onItemClick={(row) => contactDrawer.open()}
          variant={'compact'}
          visibleColumns={visibleColumns}
          setVisibleColumns={setVisibleColumns}
          hasSelection
          selection={selection}
          setSelection={setSelection}
        />
      </Box>
    </Flex>
  );
}

export default InspectionFollowUpTab;

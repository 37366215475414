import { lazy, useEffect } from 'react';
import { push } from '@rexlabs/whereabouts';
import config from 'shared/utils/config';

import { projectsRoutes } from 'features/_experiment-projects/data/routes';
import { campaignsRoutes } from 'features/_experiment-campaigns/data/routes';
import { listingsRoutes } from 'features/_experiment-listings/data/routes';
import { reportingRoutes } from 'shell/src/features/custom-reporting/data/routes';
import { LeadsRecordScreen } from 'features/leads/screens/leads-list-screen';
import ApplicationsListScreen from 'features/tenancy-applications/screens/applications-list-screen';
import AllBills from 'features/bills/screens/all-bills';

export default {
  LOGIN: {
    config: {
      path: '/login',
      Component: () => {
        window.location.href = `${config.AUTHENTICATION_SERVICE_FRONTEND_URL}?app_id=rex_crm`; // &source=${window.location.href}
        return null;
      }
    }
  },

  CALENDAR: {
    config: {
      path: '/calendar/',
      Component: lazy(() => import('view/screens/calendar/index'))
    },
    CONNECT: {
      config: {
        path: '/calendar/connect',
        Component: lazy(() => import('view/dialogs/calendar/connect/complete'))
      }
    },
    CONNECT_EXTENDED: {
      config: {
        path: '/calendar/connect-extended',
        Component: lazy(() => import('view/dialogs/calendar/connect/extended'))
      }
    }
  },

  NOT_FOUND: {
    config: {
      path: '/not-found',
      Component: require('view/screens/not-found').default
    }
  },

  ERROR: {
    config: {
      path: '/error',
      Component: require('view/screens/error').default
    }
  },

  DASHBOARD_V_1: {
    config: {
      path: '/dashboards',
      Component: lazy(() => import('src/features/dashboards/screens/index'))
    }
  },

  APPRAISALS_PIPELINE: {
    config: {
      path: '/appraisals/pipeline',
      Component: lazy(() =>
        import(
          'features/pipelines/modules/appraisals/screens/pipeline-appraisals'
        )
      )
    }
  },

  DASHBOARD_REDIRECT: {
    config: {
      path: '/dashboard',
      // HACK - using a component to redirect while the routing code gets refactored
      // CH - https://app.clubhouse.io/rexlabs/story/53216/routing-code-refactor
      // below should be replaced with:
      // redirect:{ path: '/home'}
      Component: () => {
        useEffect(() => push({ config: { path: '/home' } }), []);
        return null;
      }
    }
  },

  LEADS_RECORD: {
    config: () => ({
      path: '/leads',
      // NOTE: We were using lazy loading here like above, but it seemed to cause
      // the page to create 2 lists in redux. See here for more info:
      // https://app.shortcut.com/rexlabs/story/67343/investigate-why-lazy-load-is-triggering-double-list-view-requests
      Component: LeadsRecordScreen
    })
  },

  APPLICATIONS_RECORD: {
    config: () => ({
      path: '/tenancy-applications',
      // NOTE: We were using lazy loading here like above, but it seemed to cause
      // the page to create 2 lists in redux. See here for more info:
      // https://app.shortcut.com/rexlabs/story/67343/investigate-why-lazy-load-is-triggering-double-list-view-requests
      Component: ApplicationsListScreen
    })
  },

  ALL_BILLS: {
    config: () => ({
      path: '/bills',
      // NOTE: We were using lazy loading here like above, but it seemed to cause
      // the page to create 2 lists in redux. See here for more info:
      // https://app.shortcut.com/rexlabs/story/67343/investigate-why-lazy-load-is-triggering-double-list-view-requests
      Component: AllBills
    })
  },

  GRAPHIQL: {
    config: {
      path: '/development/graphiql/',
      Component: lazy(() => import('src/features/graphql/screens/graphiql.tsx'))
    }
  },

  CHAKRA_TEST: {
    config: {
      path: '/development/chakra-test/',
      Component: lazy(() =>
        import('src/features/_experiment-chakra-test/screens/chakra-test.tsx')
      )
    }
  },

  ...projectsRoutes,
  ...campaignsRoutes,
  ...listingsRoutes,
  ...reportingRoutes
};

import { insertCss } from '@rexlabs/styling';

export function initTheme() {
  insertCss(`
  
  html, body, div, span, applet, object, iframe,
  h1, h2, h3, h4, h5, h6, p, blockquote,
  a, abbr, acronym, address, big, cite, code,
  del, dfn, em, img, ins, kbd, q, s, samp,
  small, strike, strong, sub, sup, tt, var,
  b, u, i, center,
  dl, dt, dd, ol, ul, li,
  fieldset, form, label, legend,
  table, caption, tbody, tfoot, thead, tr, th, td,
  article, aside, canvas, details, embed,
  figure, figcaption, footer, header, hgroup,
  menu, nav, output, ruby, section, summary,
  time, mark, audio, video, pre {
      margin: 0;
      padding: 0;
      border: 0;
      font-size: 100%;
      font: inherit;
      /*vertical-align: baseline;*/
  }
  /* HTML5 display-role reset for older browsers */
  article, aside, details, figcaption, figure,
  footer, header, hgroup, menu, nav, section {
      display: block;
  }
  body {
      line-height: 1;
  }
  ol, ul {
      list-style: none;
  }
  blockquote, q {
      quotes: none;
  }
  blockquote:before, blockquote:after,
  q:before, q:after {
      content: '';
      content: none;
  }
  table {
      border-collapse: collapse;
      border-spacing: 0;
  }
  button, input[type='button']:not(.btn) {
      padding: 0;
      -webkit-border-radius: initial;
      -moz-border-radius: initial;
      border-radius: initial;
  }
  }

  `);
  insertCss(`
    html {
      font-size: 62.5% !important;
      height: 100%;
      overflow: hidden;

      box-sizing: border-box;
      font-family: "proxima-nova", 'Proxima Nova', helvetica, arial, sans-serif;
      -webkit-font-smoothing: antialiased;
    }

    body, #app {
      height: 100%;
    }

    button, input, textarea, select {
      font: inherit;
    }

    *, *:before, *:after {
      box-sizing: inherit;
    }
  `);
}

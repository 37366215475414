/*
|-------------------------------------------------------------------------------
| Rex 2 Dialog Frames Stack
|-------------------------------------------------------------------------------
|
| Rex 2 Dialogs can be loaded from the server on a /dialog/<dialog view> URL.
|
| This "Dialog Stack" will keep track of any number of iframes loading a dialog
| URL. It will also handle responding to events, like opening and closing of the
| dialog, by pushing or popping the dialog from the DOM.
|
*/

import React, { PureComponent } from 'react';
import _ from 'lodash';
import { styled, StyleSheet } from '@rexlabs/styling';
import { ZINDEX } from 'theme';
import { Autobind } from 'utils/decorators';
import { withModel } from '@rexlabs/model-generator';
import ui from 'data/models/custom/ui';
import Spinner from 'shared/components/spinner';
import { DIALOG_ACTIONS, dialogEmitter } from 'data/emitters/dialogs';
import { OVERLAY_ACTIONS, overlayEmitter } from 'data/emitters/overlays';

import { ClassicDialogFrame, ShellDialogFrame } from './dialog/frame';
import { ShellOverlayFrame } from './overlay/frame';

const defaultStyles = StyleSheet({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: ZINDEX.REXDIALOG,
    pointerEvents: 'none'
  },
  stretch: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0
  },
  hidden: {
    display: 'none'
  }
});

@withModel(ui)
@styled(defaultStyles)
@Autobind
class DialogOverlayStack extends PureComponent {
  state = {
    stack: [],
    isLoading: false
  };

  stopLoading() {
    this.setState(() => ({
      isLoading: false
    }));
  }

  add(state, newItem) {
    // Note - sort causes different effects based on browser. _.sortBy doesn't.
    // This will control element order but dialog-overlay.js applies z-index, so this may have no effect.
    return _.sortBy([...state.stack, newItem], (a) => (a.alwaysOnTop ? 1 : 0));
  }

  pop() {
    const state = this.state;
    const newStack = [...state.stack];
    newStack.pop();

    this.setState({ ...state, stack: newStack });

    // Also, turn off Shell's overlay sync, which could be active.
    if (state.stack.length === 1 && newStack.length === 0) {
      this.props.ui.updateDialogActive(false);
    }
  }

  remove(state, dialogOrOverlay) {
    const newStack = state.stack.filter(
      (item) =>
        !(
          dialogOrOverlay.uuid === item.uuid &&
          item.id === dialogOrOverlay.callName
        )
    );

    // Also, turn off Shell's overlay sync, which could be active.
    if (state.stack.length === 1 && newStack.length === 0) {
      this.props.ui.updateDialogActive(false);
    }

    return newStack;
  }

  UNSAFE_componentWillMount() {
    // These actions will be triggered by calling the withRex2Dialog
    // 'rexDialog.open' and 'rexDialog.close' functions
    const classicDialogUnsub = dialogEmitter.subscribe(
      DIALOG_ACTIONS.OPEN_CLASSIC,
      (dialogConfig) => {
        // NOTE: setting loading state seperate to make sure react doesn't
        // get confused when batching state stuff...
        this.setState({ isLoading: true }, () => {
          this.setState((state) => ({
            stack: this.add(state, {
              type: 'dialog',
              id: dialogConfig.callName,
              uuid: dialogConfig.uuid,
              element: (
                <ClassicDialogFrame
                  key={dialogConfig.uuid}
                  onLoad={this.stopLoading}
                  {...dialogConfig}
                />
              )
            })
          }));
        });
      }
    );

    const shellDialogUnsub = dialogEmitter.subscribe(
      DIALOG_ACTIONS.OPEN,
      (dialogConfig) => {
        this.setState({ isLoading: true }, () => {
          this.setState((state) => ({
            stack: this.add(state, {
              type: 'dialog',
              id: dialogConfig.callName,
              uuid: dialogConfig.uuid,
              alwaysOnTop:
                !!dialogConfig.props.alwaysOnTop || !!dialogConfig.alwaysOnTop,
              element: (
                <ShellDialogFrame
                  uuid={dialogConfig.uuid}
                  key={dialogConfig.callName}
                  onLoad={this.stopLoading}
                  dialogConfig={dialogConfig}
                  {...dialogConfig.props}
                />
              )
            })
          }));
        });
      }
    );

    const shellOverlayUnsub = overlayEmitter.subscribe(
      OVERLAY_ACTIONS.OPEN,
      (overlayConfig) => {
        this.setState({ isLoading: true }, () => {
          this.setState((state) => ({
            stack: this.add(state, {
              id: overlayConfig.callName,
              uuid: overlayConfig.uuid,
              element: (
                <ShellOverlayFrame
                  uuid={overlayConfig.uuid}
                  key={overlayConfig.callName}
                  onLoad={this.stopLoading}
                  overlayConfig={overlayConfig}
                  {...overlayConfig.props}
                />
              )
            }),
            isLoading: false
          }));
        });
      }
    );

    const closeDialogUnsub = dialogEmitter.subscribe(
      DIALOG_ACTIONS.CLOSE,
      (dialogConfig) => {
        this.setState((state) => ({
          ...state,
          stack: this.remove(state, dialogConfig)
        }));
      }
    );

    const closeAllDialogsUnsub = dialogEmitter.subscribe(
      DIALOG_ACTIONS.CLOSE_ALL,
      () => {
        this.setState((state) => ({
          ...state,
          stack: []
        }));
      }
    );

    const closeOverlayUnsub = overlayEmitter.subscribe(
      OVERLAY_ACTIONS.CLOSE,
      (overlayConfig) => {
        this.setState((state) => ({
          ...state,
          stack: this.remove(state, overlayConfig)
        }));
      }
    );

    this.unsubs = [
      classicDialogUnsub,
      shellDialogUnsub,
      shellOverlayUnsub,
      closeDialogUnsub,
      closeAllDialogsUnsub,
      closeOverlayUnsub
    ];

    window.document.addEventListener('keydown', this.handleKeyDown);
  }

  componentWillUnmount() {
    this.unsubs.forEach((fn) => fn());

    window.document.removeEventListener('keydown', this.handleKeyDown);
  }

  // Added to allow closing of dialog's via escape key to match Classic dialog behaviour
  handleKeyDown(e) {
    if (e.key === 'Escape') {
      this.pop();
    }
  }

  render() {
    const { styles: s, ...props } = this.props;
    const { stack, isLoading } = this.state;
    const containsDialog = stack.find((item) => {
      return item.type === 'dialog';
    });

    return stack.length > 0 ? (
      <div {...s.with('container', 'stretch')(props)}>
        {stack.map((d) => d.element)}
        <Spinner key='spinner' {...s({ hidden: !isLoading })} />
      </div>
    ) : null;
  }
}

export default DialogOverlayStack;

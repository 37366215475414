import { defineRecipe } from '@chakra-ui/react';

export const badgeRecipe = defineRecipe({
  className: 'chakra-badge',
  base: {
    display: 'inline-flex',
    alignItems: 'center',
    borderRadius: 'md',
    gap: '1',
    fontWeight: 'semibold',
    fontSize: '12px',
    lineHeight: '14px',
    fontVariantNumeric: 'tabular-nums',
    whiteSpace: 'nowrap',
    userSelect: 'none'
  },
  variants: {
    variant: {
      solid: {
        bg: 'colorPalette.900',
        color: 'white'
      }
    },
    size: {
      xs: {
        textStyle: '2xs',
        px: '1',
        minH: '4'
      },
      sm: {
        textStyle: 'xs',
        px: '1.5',
        minH: '5'
      },
      md: {
        textStyle: 'sm',
        px: '2',
        minH: '6'
      },
      lg: {
        textStyle: 'sm',
        px: '2.5',
        minH: '7'
      }
    }
  },
  defaultVariants: {
    variant: 'solid',
    size: 'sm'
  }
});

import { Drawer as ChakraDrawer, Heading, Portal } from '@chakra-ui/react';
import { forwardRef } from 'react';
import { ChakraRoot } from 'src/lib/chakra/chakra-root';
import { CloseButton } from 'components/chakra/close-button/design-system/close-button';

interface DrawerContentProps extends ChakraDrawer.ContentProps {
  portalled?: boolean;
  portalRef?: React.RefObject<HTMLElement>;
  offset?: ChakraDrawer.ContentProps['padding'];
}

export const DrawerContent = forwardRef<HTMLDivElement, DrawerContentProps>(
  function DrawerContent(props, ref) {
    const { children, portalled = true, portalRef, offset, ...rest } = props;
    return (
      <Portal disabled={!portalled} container={portalRef}>
        <ChakraRoot>
          <ChakraDrawer.Positioner padding={offset} pointerEvents={'none'}>
            <ChakraDrawer.Content ref={ref} {...(rest as any)} asChild={false}>
              {children}
            </ChakraDrawer.Content>
          </ChakraDrawer.Positioner>
        </ChakraRoot>
      </Portal>
    );
  }
);

export const DrawerCloseTrigger = forwardRef<
  HTMLButtonElement,
  ChakraDrawer.CloseTriggerProps
>(function DrawerCloseTrigger(props, ref) {
  return (
    <ChakraDrawer.CloseTrigger
      position='absolute'
      top='2'
      insetEnd='2'
      {...(props as any)}
      asChild
    >
      <CloseButton size='md' ref={ref} />
    </ChakraDrawer.CloseTrigger>
  );
});

export const DrawerTitle = forwardRef<
  HTMLHeadingElement,
  ChakraDrawer.TitleProps
>(function DrawerTitle(props, ref) {
  return (
    <ChakraDrawer.Title {...(props as any)} asChild>
      <Heading as='h2' size='lg' lineHeight='1.2' ref={ref}>
        {props.children}
      </Heading>
    </ChakraDrawer.Title>
  );
});

export const DrawerDescription = forwardRef<
  HTMLParagraphElement,
  ChakraDrawer.DescriptionProps
>(function DrawerDescription(props, ref) {
  return (
    <ChakraDrawer.Description color='fg.muted' ref={ref} {...(props as any)} />
  );
});

export const DrawerTrigger = ChakraDrawer.Trigger;
export const DrawerRoot = ChakraDrawer.Root;
export const DrawerFooter = ChakraDrawer.Footer;
export const DrawerHeader = ChakraDrawer.Header;
export const DrawerBody = ChakraDrawer.Body;
export const DrawerBackdrop = ChakraDrawer.Backdrop;

import { Tabs as ChakraTabs, TabsRootProps } from '@chakra-ui/react';
import { ReactNode } from 'react';

interface Tab {
  value: string;
  label: string;
  children?: ReactNode;
}

export interface TabsProps extends TabsRootProps {
  tabs: Tab[];
}
export function Tabs({ tabs, ...rest }: TabsProps) {
  return (
    <ChakraTabs.Root
      defaultValue={rest.defaultValue || tabs[0]?.value}
      {...(rest as any)}
    >
      <ChakraTabs.List>
        {tabs.map((t) => (
          <ChakraTabs.Trigger key={t.value} value={t.value}>
            {t.label}
          </ChakraTabs.Trigger>
        ))}
      </ChakraTabs.List>
      {tabs.map((t) => (
        <ChakraTabs.Content key={t.value} value={t.value}>
          {t.children}
        </ChakraTabs.Content>
      ))}
    </ChakraTabs.Root>
  );
}

import {
  DefaultCell,
  DefaultCellProps
} from 'components/record-list-screen/cells/default';
import ActionButton from 'components/chakra/button/patterns/action-button';
import { ICONS } from 'shared/components/icon';

function AddFeedbackCell(props: DefaultCellProps) {
  return (
    <DefaultCell
      {...props}
      value={
        <ActionButton
          iconType={ICONS.ADD}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          Add Feedback
        </ActionButton>
      }
    />
  );
}

export default AddFeedbackCell;

import {
  ChakraContextProvider,
  SystemContext,
  useChakraContext
} from '@chakra-ui/react';

export interface ChakraProviderProps {
  value: SystemContext;
  children: React.ReactNode;
}

function ChakraProvider(props: ChakraProviderProps) {
  const { value: sys, children } = props;

  return <ChakraContextProvider value={sys}>{children}</ChakraContextProvider>;
}

export { ChakraProvider, useChakraContext };

import { HTMLProps } from 'react';
import { insertCss } from '@rexlabs/styling';

insertCss(`
   .chakra-root {
      font-size: 100%;
    }
    .chakra-root * {
      all: revert-layer;
    }
  `);

export function ChakraRoot(props: HTMLProps<HTMLDivElement>) {
  return <div className={'chakra-root'}>{props.children}</div>;
}

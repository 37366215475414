import {
  MenuContent,
  MenuItem,
  MenuItemGroup,
  MenuRoot,
  MenuTrigger
} from 'components/chakra/menu/design-system/menu';
import { ReactNode } from 'react';
import { MenuItemProps, MenuRootProps, Text } from '@chakra-ui/react';

interface MenuItem {
  id: string;
  label: ReactNode;
  onClick?: MenuItemProps['onClick'];
  visible?: boolean;
  group?: string;
}

export interface ActionMenuProps extends MenuRootProps {
  children: ReactNode;
  items: MenuItem[];
}
export function ActionMenu({ children, items, ...rest }: ActionMenuProps) {
  const groups = items.reduce((result, item) => {
    const group = item.group || 'none';
    result[group] = [...(result[group] || []), item];
    return result;
  }, {} as Record<string, MenuItem[]>);

  return (
    <MenuRoot {...rest} positioning={{ offset: { mainAxis: 0 } }}>
      <MenuTrigger asChild>{children}</MenuTrigger>
      <MenuContent>
        {Object.entries(groups).map(([groupKey, g]) => (
          <MenuItemGroup
            key={groupKey}
            title={groupKey === 'none' ? undefined : groupKey}
          >
            {g.map((item) =>
              'visible' in item && !item.visible ? null : (
                <MenuItem onClick={item.onClick} key={item.id} value={item.id}>
                  {typeof item.label === 'string' ? (
                    <Text textStyle={'body'}>{item.label}</Text>
                  ) : (
                    item.label
                  )}
                </MenuItem>
              )
            )}
          </MenuItemGroup>
        ))}
      </MenuContent>
    </MenuRoot>
  );
}

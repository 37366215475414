import { tabsAnatomy } from '@chakra-ui/react/anatomy';
import { defineSlotRecipe } from '@chakra-ui/react';

export const tabsSlotRecipe = defineSlotRecipe({
  slots: tabsAnatomy.keys(),
  className: 'chakra-tabs',
  base: {
    root: {
      position: 'relative',

      _horizontal: {
        display: 'block'
      },
      _vertical: {
        display: 'flex'
      }
    },
    trigger: {
      outline: 0,
      display: 'flex',
      alignItems: 'center',
      fontWeight: 'medium',
      position: 'relative',
      gap: '2',
      _focusVisible: {
        zIndex: 1,
        outline: '2px solid',
        outlineColor: 'focusRing'
      },
      _disabled: {
        cursor: 'not-allowed',
        opacity: 0.5
      }
    },
    list: {
      display: 'inline-flex',
      position: 'relative',
      isolation: 'isolate',
      '--tabs-indicator-shadow': 'shadows.xs',
      '--tabs-indicator-bg': 'colors.bg',
      _horizontal: {
        flexDirection: 'row'
      },
      _vertical: {
        flexDirection: 'column'
      }
    },
    content: {
      ms: 'var(--tabs-gap-x)',
      width: 'var(--tabs-width)',
      height: 'var(--tabs-height)',
      bg: 'white',
      _focusVisible: {
        outline: '2px solid',
        outlineColor: 'focusRing'
      },
      _horizontal: {
        '--tabs-width': '100%',
        '--tabs-gap-y': 'spacing.2'
      },
      _vertical: {
        '--tabs-height': '100%',
        '--tabs-gap-x': 'spacing.2'
      }
    },
    indicator: {
      width: 'var(--width)',
      height: 'var(--height)',
      borderRadius: 'var(--tabs-indicator-radius)',
      bg: 'var(--tabs-indicator-bg)',
      shadow: 'var(--tabs-indicator-shadow)',
      zIndex: -1
    }
  },

  variants: {
    border: {
      true: {
        content: {
          borderColor: 'grey.700',
          borderTopWidth: '2px'
        }
      }
    },
    theme: {
      darkGrey: {
        trigger: {
          color: 'white',
          bg: 'grey.700',
          _selected: {
            bg: 'grey.950',
            color: 'white'
          }
        }
      },
      lightGrey: {
        trigger: {
          color: 'grey.700',
          bg: 'grey.50',
          _selected: {
            bg: 'grey.700',
            color: 'white'
          }
        }
      }
    },
    fitted: {
      true: {
        list: {
          display: 'flex'
        },
        trigger: {
          flex: 1,
          textAlign: 'center',
          justifyContent: 'center'
        }
      }
    },

    justify: {
      start: {
        list: {
          justifyContent: 'flex-start'
        }
      },
      center: {
        list: {
          justifyContent: 'center'
        }
      },
      end: {
        list: {
          justifyContent: 'flex-end'
        }
      }
    },

    size: {
      md: {
        root: {
          '--tabs-trigger-radius': 'radii.sm',
          '--tabs-indicator-radius': 'radii.sm'
        },
        trigger: {
          py: '1.5',
          px: '4',
          textStyle: 'buttonLabel'
        }
      }
    },

    variant: {
      outline: {
        list: {
          gap: 1
        },
        trigger: {
          h: '8',
          minW: '8',
          px: '3',
          color: 'white',
          bg: 'grey.700',
          _selected: {
            bg: 'grey.950',
            color: 'white'
          },
          _horizontal: {
            borderTopRadius: 'xs',
            marginBottom: 'var(--line-offset)',
            marginEnd: { _notLast: 'var(--line-offset)' }
          },
          _vertical: {
            borderStartRadius: 'xs',
            marginEnd: 'var(--line-offset)',
            marginBottom: { _notLast: 'var(--line-offset)' },
            _selected: {
              borderColor: 'border',
              borderEndColor: 'transparent'
            }
          }
        }
      }
    }
  },

  defaultVariants: {
    size: 'md',
    variant: 'outline',
    theme: 'lightGrey',
    border: true
  }
});

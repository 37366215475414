import { Box, Button, Flex } from '@chakra-ui/react';
import { useState } from 'react';
import { RecordListTable } from 'components/record-list-screen/table';
import Icon, { ICONS } from 'shared/components/icon';
import RedBtnActionMenu from 'components/chakra/menu/patterns/red-btn-action-menu';
import { useDialog } from 'hooks/use-dialog';
import InterestLevelCard from 'features/buyer-mode/components/interest-level-card';
import PipelineCard, {
  PipelinePosition
} from 'features/buyer-mode/components/buyer-pipeline-card';
import { ActionMenu } from 'components/chakra/menu/patterns/action-menu';
import { Selection } from 'components/record-list-screen/types';
import {
  FilterPopout,
  filterPopoutFieldConfigs
} from 'components/popouts/filter-popout';
import { PLACEMENTS } from '@rexlabs/tooltip';
import interestLevel from 'data/models/system-lists/interest-level';
import { DateRangeSelect } from 'src/view/components/input/select';

const mockData = [
  {
    name: 'John Doe',
    interest_level: 'Hot',
    email: 'john.doe@example.com'
  },
  {
    name: 'Jane Doe',
    interest_level: 'Warm',
    email: 'jane.doe@example.com'
  },
  {
    name: 'John Doe',
    interest_level: 'Hot',
    email: 'john.doe@example.com'
  },
  {
    name: 'Jane Doe',
    interest_level: 'Warm',
    email: 'jane.doe@example.com'
  },
  {
    name: 'John Doe',
    interest_level: 'Hot',
    email: 'john.doe@example.com'
  },
  {
    name: 'Jane Doe',
    interest_level: 'Warm',
    email: 'jane.doe@example.com'
  }
];

const columns = [
  {
    id: 'person',
    label: 'Person',
    selector: (row) => row.name
  },
  {
    id: 'interest_level',
    label: 'Interest',
    selector: (row) => row.interest_level
  },
  {
    id: 'enquiries',
    label: 'Enquiries',
    selector: (row) => row.email
  }
];

const mockInterestData = [
  {
    id: 'hot',
    label: 'Hot buyers',
    count: 234,
    icon: ICONS.BUILDING,
    color: 'red.500'
  },
  {
    id: 'warm',
    label: 'Warm buyers',
    count: 99,
    icon: ICONS.BUILDING,
    color: 'yellow.500'
  },
  {
    id: 'cold',
    label: 'Cold buyers',
    count: 233,
    icon: ICONS.BUILDING,
    color: 'blue.900'
  },
  {
    id: 'total',
    label: 'Total buyers',
    count: 73,
    icon: ICONS.BUILDING,
    color: 'green.500'
  }
];

const mockPipelineData = [
  {
    id: 'enquiries',
    label: 'Enquiries',
    count: 20
  },
  {
    id: 'upcoming-attendees',
    label: 'Upcoming attendees',
    count: 89
  },
  {
    id: 'past-attendees',
    label: 'Past attendees',
    count: 55
  },
  {
    id: 'contract-sent',
    label: 'Contract sent',
    count: 76
  },
  {
    id: 'offers',
    label: 'Offers',
    count: 234
  }
];

const filters = [
  {
    field: 'interest_level',
    label: 'interest level',
    ...filterPopoutFieldConfigs.valueListSelect({
      models: [interestLevel],
      multi: true
    })
  },
  {
    field: 'enquired',
    label: 'enquired',
    ...filterPopoutFieldConfigs.boolean()
  },
  {
    field: 'attendeed',
    label: 'interest level',
    ...filterPopoutFieldConfigs.select([
      {
        value: 1,
        label: 'One'
      },
      {
        value: 2,
        label: 'Two'
      },
      {
        value: 3,
        label: 'Three'
      }
    ])
  },
  {
    field: 'offers',
    label: 'offers submitted',
    ...filterPopoutFieldConfigs.boolean()
  },
  {
    field: 'tags',
    label: 'tags',
    ...filterPopoutFieldConfigs.valueListSelect({
      models: [interestLevel],
      multi: true
    })
  },
  {
    field: 'contacted_date',
    label: 'last contacted date',
    valueType: 'range',
    Input: DateRangeSelect,
    // TODO: Add toCriteria logic
    toCriteria: (filter, value) => {
      return [];
    },
    // TODO: Add fromCriteria logic
    fromCriteria: (filter, criteria) => ''
  }
];

function BuyersTab() {
  const contactDrawer = useDialog('contactDrawer');
  const [visibleColumns, setVisibleColumns] = useState<string[]>(
    columns.map((column) => column.id)
  );
  const [selection, setSelection] = useState<Selection>({
    type: 'include',
    ids: []
  });
  const [pipelineSelection, setPipelineSelection] = useState<string>();

  return (
    <Flex direction={'column'}>
      <Box p={4} bg={'grey.50'}>
        <Flex direction={'row'} alignItems={'center'} mb={4} gap={4}>
          {mockInterestData.map((item, index) => (
            <InterestLevelCard
              key={item.id}
              isSelected={index === 0}
              onSelect={() => null}
              {...item}
            />
          ))}
        </Flex>
        <Flex direction={'row'} alignItems={'center'} px={1}>
          {mockPipelineData.map((item, index) => (
            <PipelineCard
              key={item.id}
              position={
                index === 0
                  ? PipelinePosition.FIRST
                  : index === mockPipelineData.length - 1
                  ? PipelinePosition.LAST
                  : PipelinePosition.MIDDLE
              }
              isSelected={pipelineSelection === item.id}
              onSelect={(id) => setPipelineSelection(id)}
              {...item}
            />
          ))}
        </Flex>
      </Box>
      <Box p={4}>
        <Flex justifyContent={'space-between'} alignItems={'center'} mb={4}>
          <Flex alignItems={'center'} gap={2}>
            <ActionMenu items={[{ label: 'Some Buyers List', id: 'some' }]}>
              <Button variant={'text'} textStyle={'h3'}>
                All Buyers List
                <Icon type={ICONS.DOWN} width={14} height={14} />
              </Button>
            </ActionMenu>
            <RedBtnActionMenu
              Icon={<Icon type={ICONS.ADD} width={10} height={10} />}
              items={[{ label: 'Edit', id: 'edit' }]}
            >
              Add Buyer
            </RedBtnActionMenu>
          </Flex>
          <FilterPopout
            placement={PLACEMENTS.RIGHT_START}
            title={'Filter'}
            filters={filters}
            criteria={null}
            onFilter={() => {}}
            columns={2}
          />
        </Flex>
        <RecordListTable
          columns={columns}
          items={mockData}
          isLoading={false}
          onItemClick={(row) => contactDrawer.open()}
          variant={'compact'}
          visibleColumns={visibleColumns}
          setVisibleColumns={setVisibleColumns}
          hasSelection
          selection={selection}
          setSelection={setSelection}
        />
      </Box>
    </Flex>
  );
}

export default BuyersTab;

import { Flex, Text, chakra } from '@chakra-ui/react';
import { ReactNode } from 'react';
import { ActionMenu, ActionMenuProps } from './action-menu';

interface RedBtnActionMenuProps extends ActionMenuProps {
  Icon: ReactNode;
}

const RedBtnActionMenu = ({
  Icon,
  children,
  ...rest
}: RedBtnActionMenuProps) => {
  return (
    <ActionMenu {...rest}>
      <chakra.button display={'flex'} alignItems={'center'} cursor={'pointer'}>
        <Flex
          justifyContent={'center'}
          alignItems={'center'}
          bg={'red.900'}
          w={'25px'}
          h={'25px'}
          rounded={'full'}
          color={'white'}
          mr={2}
        >
          {Icon}
        </Flex>
        <Text textStyle={'bodySemibold'}>{children}</Text>
      </chakra.button>
    </ActionMenu>
  );
};

export default RedBtnActionMenu;

import { defineTextStyles } from '@chakra-ui/react';

export const textStyles = defineTextStyles({
  body: {
    description: 'The body text style - used in paragraphs',
    value: {
      fontWeight: '400',
      fontSize: '14px',
      lineHeight: '17px'
    }
  },
  bodySemibold: {
    description: 'The body text style - used in paragraphs',
    value: {
      fontWeight: '600',
      fontSize: '14px',
      lineHeight: '17px'
    }
  },
  bodyItalic: {
    description: 'The body text style - used in paragraphs',
    value: {
      fontWeight: '600',
      fontSize: '14px',
      fontStyle: 'italic',
      lineHeight: '17px'
    }
  },
  small: {
    description: 'The body text style - used in paragraphs',
    value: {
      fontWeight: '400',
      fontSize: '12px',
      lineHeight: '14px'
    }
  },
  smallSemibold: {
    description: 'The body text style - used in paragraphs',
    value: {
      fontWeight: '600',
      fontSize: '12px',
      lineHeight: '14px'
    }
  },
  smallItalic: {
    description: 'The body text style - used in paragraphs',
    value: {
      fontWeight: '600',
      fontSize: '12px',
      fontStyle: 'italic',
      lineHeight: '14px'
    }
  },
  h1: {
    description: 'The body text style - used in paragraphs',
    value: {
      fontWeight: '600',
      fontSize: '27px',
      lineHeight: '28px'
    }
  },
  h2: {
    description: 'The body text style - used in paragraphs',
    value: {
      fontWeight: '600',
      fontSize: '17px',
      lineHeight: '20px'
    }
  },
  h3: {
    description: 'The body text style - used in paragraphs',
    value: {
      fontWeight: '600',
      fontSize: '16px',
      lineHeight: '16px'
    }
  },
  buttonLabel: {
    description: 'The body text style - used in paragraphs',
    value: {
      fontWeight: '600',
      fontSize: '13px',
      lineHeight: '17px'
    }
  },
  link: {
    description: 'The body text style - used in paragraphs',
    value: {
      fontWeight: '600',
      fontSize: '14px',
      lineHeight: '17px'
    }
  },
  linkUnderline: {
    description: 'The body text style - used in paragraphs',
    value: {
      fontWeight: '600',
      fontSize: '12px',
      lineHeight: '17px',
      textDecoration: 'underline'
    }
  }
});

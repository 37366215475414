import { Tabs } from 'components/chakra/tabs/patterns/tabs';
import { Box } from '@chakra-ui/react';
import { ChakraRoot } from 'src/lib/chakra/chakra-root';
import BuyersTab from 'features/buyer-mode/components/buyers-tab';
import InspectionFollowUpTab from 'features/buyer-mode/components/inspection-follow-up-tab/inspection-follow-up-tab';

export default function ListingBuyersTab() {
  return (
    <ChakraRoot>
      <Box width={'100%'}>
        <Tabs
          theme={'darkGrey'}
          tabs={[
            {
              value: 'buyers',
              label: 'Buyers',
              children: <BuyersTab />
            },
            {
              value: 'inspection-follow-up',
              label: 'Inspection Follow Up',
              children: <InspectionFollowUpTab />
            }
          ]}
        />
      </Box>
    </ChakraRoot>
  );
}

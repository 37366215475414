import { Box, chakra, Flex, Text } from '@chakra-ui/react';

const firstMask = `data:image/svg+xml;utf8,<svg preserveAspectRatio="none" viewBox="0 0 253 95" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M237.365 0H4C1.79086 0 0 1.79086 0 4V91C0 93.2091 1.79086 95 4 95H237.365L253 47.5L237.365 0Z" fill="white"/></svg>`;
const midMask = `data:image/svg+xml;utf8,<svg preserveAspectRatio="none" viewBox="0 0 265 95" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M249.235 0H0.600006L15.9651 47.5L0.600006 95H249.235L264.6 47.5L249.235 0Z" fill="white"/></svg>`;
const lastMask = `data:image/svg+xml;utf8,<svg preserveAspectRatio="none" viewBox="0 0 237 95" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M232.2 0H0.200073L14.7843 47.5L0.200073 95H232.2C234.409 95 236.2 93.2091 236.2 91V47.8571V4C236.2 1.79086 234.409 0 232.2 0Z" fill="white"/></svg>`;

export enum PipelinePosition {
  FIRST = 'first',
  MIDDLE = 'middle',
  LAST = 'last'
}

const MaskMap = {
  [PipelinePosition.FIRST]: firstMask,
  [PipelinePosition.MIDDLE]: midMask,
  [PipelinePosition.LAST]: lastMask
};

export interface BuyerPipelineCardProps {
  id: string;
  label: string;
  count: number;
  isSelected: boolean;
  onSelect: (id: string) => void;
  position?: PipelinePosition;
  pipelineCount?: number;
}
function PipelineCard({
  id,
  label,
  count,
  isSelected,
  onSelect,
  position
}: BuyerPipelineCardProps) {
  return (
    <Box
      flex={1}
      mx={-1}
      p={0}
      _hover={{ filter: 'drop-shadow(0px 0px 4px rgba(0,0,0,0.2))' }}
      backgroundImage={position ? `url(${MaskMap[position]})` : undefined}
    >
      <chakra.button
        key={id}
        bg={'white'}
        p={3}
        pl={5}
        w='full'
        h='full'
        cursor={'pointer'}
        onClick={() => onSelect(id)}
        role='group'
        style={{
          maskImage: position ? `url(${MaskMap[position]})` : undefined,
          maskSize: 'contain',
          maskRepeat: 'no-repeat',
          maskPosition: 'center'
        }}
      >
        <Flex flexDirection={'column'} alignItems={'flex-start'} pl={4}>
          <chakra.div
            rounded={'sm'}
            h={'4px'}
            w={'40px'}
            bg={isSelected ? 'blue.50' : 'grey.50'}
            _groupHover={{ bg: 'blue.50' }}
            mb={3}
          />
          <Text
            textStyle={'bodySemibold'}
            color={isSelected ? 'grey.950' : 'grey.900'}
            _groupHover={{ color: 'grey.950' }}
            mb={1}
          >
            {label}
          </Text>
          <Text
            textStyle={'h1'}
            color={'accent.blue.500'}
            borderBottomWidth={1}
            borderBottomStyle={'solid'}
            borderBottomColor={'accent.blue.500'}
          >
            {count}
          </Text>
        </Flex>
      </chakra.button>
    </Box>
  );
}

export default PipelineCard;

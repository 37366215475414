import { CacheProvider, Global } from '@emotion/react';
import { ChakraContextProvider } from '@chakra-ui/react';
import shellChakraSystem from 'src/lib/chakra/config';
import {
  CHAKRA_EMOTION_CACHE_KEY,
  chakraCache
} from 'src/lib/chakra/chakra-cache';

export function ChakraGlobalStyles() {
  return (
    <CacheProvider value={chakraCache}>
      <ChakraContextProvider value={shellChakraSystem}>
        <>
          {!shellChakraSystem._config.disableLayers ? (
            <Global styles={shellChakraSystem.layers.atRule} />
          ) : null}
          <Global
            styles={[
              shellChakraSystem.getPreflightCss(),
              shellChakraSystem.getGlobalCss(),
              shellChakraSystem.getTokenCss()
            ]}
          />
        </>
      </ChakraContextProvider>
    </CacheProvider>
  );
}

/**
 * When Chakra components are rendered using Knockout react bindings, its Emotion
 * styles are created in Shell, not in the iframe. This function will sync the
 * emotion styles from Shell to the iframe.
 *
 * We also do this for @rexlabs/styled components (see src/utils/styles.js)
 * however that approach is coupled to older Emotion versions and is very brittle,
 * so we're handling it entirely separately for Chakra.
 */
export function syncChakraEmotionStylesToIframe(iframe: HTMLIFrameElement) {
  function injectStyles(iframeElement: HTMLIFrameElement, node: Element) {
    const iframeDocument =
      iframeElement.contentDocument || iframeElement.contentWindow?.document;
    if (!iframeDocument) return;
    const styleElement = iframeDocument.createElement('style');
    styleElement.setAttribute(
      'data-emotion',
      node.getAttribute('data-emotion') || CHAKRA_EMOTION_CACHE_KEY
    );

    styleElement.textContent = node.textContent;
    iframeDocument.head.appendChild(styleElement);
  }

  function ensureExistingStylesInserted() {
    document.head
      .querySelectorAll(`style[data-emotion^="${CHAKRA_EMOTION_CACHE_KEY}"]`)
      .forEach((styleElement) => injectStyles(iframe, styleElement));
  }

  ensureExistingStylesInserted();

  // Set up a MutationObserver to watch for changes in the parent document's emotion styles
  // Performance is a concern here; both in terms of the general approach (this approach is perhaps not the fastest)
  // and in terms of needing to be very careful about volume of nodes being processed.
  const observer = new MutationObserver((mutations) => {
    mutations.forEach((mutation) => {
      if (mutation.type === 'childList') {
        const addedNodes = mutation.addedNodes as unknown as Element[];
        for (const node of addedNodes) {
          if (
            node.nodeType === Node.ELEMENT_NODE &&
            node.tagName === 'STYLE' &&
            node
              .getAttribute('data-emotion')
              ?.startsWith(CHAKRA_EMOTION_CACHE_KEY)
          ) {
            injectStyles(iframe, node);
          }
        }
      }
    });
  });

  observer.observe(document.head, {
    childList: true,
    subtree: true
  });
}

import { defineLayerStyles } from '@chakra-ui/react';

export const layerStyles = defineLayerStyles({
  blueText: {
    description: 'Blue text (used for links, popouts, etc.)',
    value: {
      color: 'blue.900'
    }
  }
});

import { defineRecipe } from '@chakra-ui/react';

export const buttonRecipe = defineRecipe({
  className: 'chakra-button',
  base: {
    display: 'inline-flex',
    appearance: 'none',
    alignItems: 'center',
    justifyContent: 'center',
    userSelect: 'none',
    position: 'relative',
    whiteSpace: 'nowrap',
    verticalAlign: 'middle',
    cursor: 'button',
    flexShrink: '0',
    outline: '0',
    isolation: 'isolate',
    textStyle: 'buttonLabel',
    transitionProperty: 'common',
    transitionDuration: 'moderate',
    focusVisibleRing: 'outside',
    _disabled: {
      layerStyle: 'disabled'
    },
    _icon: {
      fontSize: '1em',
      flexShrink: 0
    }
  },

  variants: {
    size: {
      md: {
        gap: '2',
        h: '8',
        minW: '8',
        borderRadius: 'xs',
        px: '3'
      }
    },

    variant: {
      text: {
        bg: 'transparent',
        p: 0,
        _hover: {
          bg: 'transparent'
        },
        _expanded: {
          bg: 'transparent'
        }
      },
      red: {
        bg: 'red.900',
        color: 'white',
        _hover: {
          bg: '#9F2316'
        },
        _expanded: {
          bg: '#9F2316'
        }
      },

      grey: {
        bg: 'grey.500',
        color: 'grey.950',
        _hover: {
          bg: 'grey.300'
        },
        _expanded: {
          bg: 'grey.300'
        }
      },

      dark: {
        bg: 'grey.950',
        color: 'white',
        _hover: {
          bg: 'grey.950'
        },
        _expanded: {
          bg: 'grey.950'
        }
      },

      circleIconBlack: {
        w: '25px',
        h: '25px',
        minW: '25px',
        color: 'white',
        rounded: 'full',
        bg: 'grey.950',
        _hover: {
          opacity: 0.8
        }
      },

      link: {
        bg: 'transparent',
        color: 'colorPalette.900',
        textDecoration: 'underline',
        _hover: {
          bg: 'colorPalette.900'
        },
        _expanded: {
          bg: 'colorPalette.900'
        }
      }
    }
  },

  defaultVariants: {
    size: 'md',
    variant: 'grey'
  }
});

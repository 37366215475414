import { Flex, Text, chakra } from '@chakra-ui/react';
import Icon, { ICONS } from 'shared/components/icon';

export interface InterestLevelCardsProps {
  id: string;
  label: string;
  color: string;
  count: number;
  icon: keyof typeof ICONS;
  isSelected: boolean;
  onSelect: (id: string) => void;
}

function InterestLevelCard({
  id,
  label,
  color,
  icon,
  count,
  isSelected,
  onSelect
}: InterestLevelCardsProps) {
  return (
    <chakra.button
      key={id}
      bg={'white'}
      p={3}
      rounded={'sm'}
      flex={1}
      onClick={() => onSelect(id)}
      position={'relative'}
      overflow={'hidden'}
      cursor={'pointer'}
      _hover={{
        shadow: 'lg'
      }}
    >
      {isSelected && (
        <chakra.div
          position={'absolute'}
          top={0}
          left={0}
          width={'4px'}
          height={'full'}
          bg={color}
        />
      )}
      <Flex
        direction={'row'}
        alignItems={'center'}
        justifyContent={'space-between'}
        gap={2}
      >
        <Flex alignItems={'center'} gap={2}>
          {/* TODO: Check chakra color */}
          <chakra.div rounded={'sm'} p={2.5} bg={`${color}/40`} color={'#888'}>
            <Icon type={icon} width={14} height={14} />
          </chakra.div>
          <Text textStyle={'bodySemibold'} color={'accent.blue.500'}>
            {label}
          </Text>
        </Flex>
        <Text
          textStyle={'h1'}
          color={'accent.blue.500'}
          borderBottomWidth={1}
          borderBottomStyle={'solid'}
          borderBottomColor={'accent.blue.500'}
        >
          {count}
        </Text>
      </Flex>
    </chakra.button>
  );
}

export default InterestLevelCard;

/* eslint-disable max-lines */
import React, { ComponentType, memo, useMemo, useState } from 'react';
import { difference, get, isFunction, isString, union } from 'lodash';
import {
  styled,
  StyleSheet,
  StylesProvider,
  useStyles
} from '@rexlabs/styling';
import Box from '@rexlabs/box';

import Checkbox from 'view/components/input/checkbox';

import { ColumnsPopout } from './popouts/columns-popout';
import { DefaultCell } from './cells/default';
import { OrderDir } from './utils/use-order-by';

import { ColumnConfig, ColumnsConfig, Selection } from './types';
import { COLORS, TEXTS } from 'theme';

function getColumnSortField(columnConfig: ColumnConfig) {
  if (columnConfig.sortable) {
    if (isString(columnConfig.sortable)) {
      return columnConfig.sortable;
    }
    return columnConfig.id;
  }

  return false;
}

const overrides = {
  Checkbox: StyleSheet({
    input: {
      '& + label': {
        margin: 0
      }
    },
    container: {
      paddingTop: '0',
      paddingBottom: '0'
    }
  })
};

const defaultStyles = StyleSheet({
  table: {
    width: '100%',
    userSelect: 'none',
    '& thead': {
      position: 'relative'
    },

    '& thead tr th': {
      background: '#f4f4f1',
      borderBottom: '2px solid #dbdbd6',
      height: '32px',
      padding: '0 0 0 15px',
      textAlign: 'left',

      '&:last-child': {
        padding: '0 15px'
      }
    },

    '& thead tr th, & tbody tr td': {
      // TODO: text abstraction?
      fontSize: '14px',
      fontWeight: 600,
      color: '#424242',
      verticalAlign: 'middle'
    },

    '& tbody tr td': {
      padding: '15px 0 15px 15px',

      '&:last-child': {
        padding: '15px'
      }
    },

    '& tbody tr:nth-child(even) td': {
      background: '#f4f4f1'
    },

    '& tbody tr:hover td': {
      background: '#e9e9e5',
      cursor: 'pointer'
    }
  },

  sortable: {
    cursor: 'pointer'
  },

  sortIcon: {
    position: 'relative',

    '&:before': {
      opacity: 0,
      transition: 'opacity .1s',
      content: '" "',
      position: 'absolute',
      right: '-15px',
      top: '50%',
      marginTop: '-1px',
      color: '#dbdbd6',
      borderLeft: '4px solid transparent',
      borderRight: '4px solid transparent',
      borderTop: '4px solid currentColor'
    },

    'th:hover > &:before': {
      opacity: 1
    }
  },

  sortIconActive: {
    '&:before': {
      opacity: 1,
      color: '#424242'
    }
  },

  sortIconActiveDesc: {
    '&:before': {
      transform: 'rotate(180deg)'
    }
  },

  checkboxCell: {
    width: '50px'
  },

  checkboxLabel: {
    margin: '0 -2px 0 8px',
    display: 'flex'
  },

  columnButton: {
    position: 'absolute',
    right: 0,
    top: 0
  },

  row: {}
});

const compactStyles = StyleSheet({
  table: {
    width: '100%',
    userSelect: 'none',
    '& thead': {
      position: 'relative'
    },

    '& thead tr th': {
      backgroundColor: COLORS.PRIMARY.SAND_LIGHT,
      height: '2.4rem',
      padding: '0.5rem 1rem',
      textAlign: 'left',
      borderBottom: 0,

      '& span': {
        ...TEXTS.CONTENT.DESCRIPTIVE_SUBTEXT_3
      },

      '&:last-child': {
        padding: '0.5rem 1rem'
      }
    },

    '& thead tr th, & tbody tr td': {
      verticalAlign: 'middle'
    },

    '& tbody tr td': {
      padding: '10px',
      position: 'relative',
      overflow: 'visible',
      ...TEXTS.CONTENT.INPUT_TEXT_MEDIUM,

      '&:last-child': {
        padding: '0 10px'
      }
    },

    '& tbody tr:nth-child(even) td': {
      background: '#f4f4f1'
    },

    '& tbody tr:hover td': {
      background: 'white',
      cursor: 'inherit'
    },

    '& tbody tr:nth-child(even):hover td': {
      background: '#f4f4f1',
      cursor: 'inherit'
    }
  },
  sortable: {
    cursor: 'pointer'
  },

  sortIcon: {
    position: 'relative',

    '&:before': {
      opacity: 0,
      transition: 'opacity .1s',
      content: '" "',
      position: 'absolute',
      right: '-15px',
      top: '45%',
      marginTop: '-1px',
      color: '#dbdbd6',
      borderLeft: '4px solid transparent',
      borderRight: '4px solid transparent',
      borderTop: '4px solid currentColor'
    },

    'th:hover > &:before': {
      opacity: 1
    }
  },

  sortIconActive: {
    '&:before': {
      opacity: 1,
      color: '#424242'
    }
  },

  sortIconActiveDesc: {
    '&:before': {
      transform: 'rotate(180deg)'
    }
  },
  light: {
    '& tbody tr:nth-child(odd) td': {
      background: '#fff'
    },

    '& tbody tr:hover td': {
      background: '#f4f4f1',
      cursor: 'inherit'
    },

    '& tbody tr:nth-child(odd):hover td': {
      background: '#fff',
      cursor: 'inherit'
    }
  },
  checkboxCell: {
    width: '37px'
  },

  columnButton: {
    position: 'absolute',
    right: 0,
    top: 0,
    '& button': {
      width: 24,
      height: 24
    }
  }
});
export interface RecordListTableProps {
  /**
   * Unique identification of table.
   *
   * Syntax:
   * `name='my-table'`
   */
  name?: string;
  /**
   * Contains all the records to be displayed on the table component.
   *
   * Syntax:
   * `items={[
   *    {
   *      id: '1',
   *      first_name: 'John',
   *      last_name: 'Smith'
   *    },
   *    ...
   * ]}`
   */
  items?: any[];
  //
  //
  /**
   * Refer to: `components/_experiment/record-list-screen/types.ts`
   *
   * See: `type ColumnConfig<Model = any>` line.
   */
  columns: ColumnsConfig;
  /**
   * Set of columns (by column id) that will be displayed to the table view.
   *
   * Syntax:
   * `visibleColumns={['first_name', 'last_name']}`
   */
  visibleColumns?: string[];
  /**
   * Function that sets the values for `visibleColumns` property.
   *
   * Syntax:
   * `setVisibleColumns(['first_name', 'last_name', 'dob'])`
   */
  setVisibleColumns?: (columnIds: string[]) => void;
  /**
   * Returns as truthy if `selection` property is not empty.
   *
   * Syntax:
   * `hasSelection={selection.length > 0}`
   */
  hasSelection?: boolean;
  /**
   * Contains the type of selection and ids of selected or unselected table rows.
   *
   * Has 2 properties: type and ids
   *
   * `type = exclude` means all items are checked by default and those specified in `ids` are unchecked.
   *
   * `type = include` means all items are unchecked by default and those specified in `ids` are checked.
   *
   * Syntax:
   * `{ type: 'exclude', ids: ['1', '2', '3'] }` - all items checked/selected except those on the `ids` prop.
   *
   * `{ type: 'include', ids: ['1', '2', '3'] }` - only items on the `ids` prop will be checked/selected.
   */
  selection?: Selection;
  /**
   * Function that sets the value for `selection` property.
   *
   * Syntax:
   * `setSelection({ type: 'include', ids: ['1', '2', '3'] })`
   */
  setSelection?: (selection: Selection) => void;
  /**
   * Contains the id or field that your data will sorted by. If you want your data will be
   * sorted by its `first_name`, then you'll specify it by `orderBy={'first_name'}`
   *
   * Syntax:
   * `orderBy={'first_name'}`
   */
  orderBy?: string;
  /**
   * Is the order direction whether ascending or descending. The actual process of changing the list order
   * is done server-side so changing the control here probably wont't have an effect.
   *
   * Syntax:
   * `orderDir={'asc'}`
   * `orderDir={'desc'}`
   */
  orderDir?: OrderDir;
  /**
   * Function that sets a value to the `orderBy` property.
   *
   * Syntax:
   * `setOrderBy('first_name')`
   */
  setOrderBy?: any;
  /**
   * Accepts boolean to tell the table that the data is still being fetched.
   * Note: RecordListTable only shows the `LoadingView` if `isLoading` is true and `items` is empty.
   *
   * Syntax:
   * `isLoading={status === 'loading'}`
   */
  isLoading?: boolean;
  /**
   * The component that will be displayed if `items` will not have any content.
   *
   * Syntax:
   * `EmptyView={<NoDataComponent />}`
   */
  EmptyView?: ComponentType<any>;
  /**
   * The component that will be displayed if `isLoading` will be true.
   *
   * Syntax:
   * `LoadingView={<LoadingSpinner />}`
   */
  LoadingView?: ComponentType<any>;
  /**
   * `allIds` stores the ids of all items including those not yet visible in the table.
   *
   * This can be used for pagination, or showing the total records, or navigating to the next/previous record.
   *
   * Syntax:
   * `allIds={['1','2','3','4']}`
   */
  allIds?: (number | string)[];
  /**
   * Function that will be triggered when you click a row on the table.
   * The params includes the current selected record and `allIds`.
   *
   * Syntax:
   * `onItemClick={(item, allIds) => showInModal(item, allIds.length)}`
   */
  onItemClick?: (item: any, allIds: (string | number)[]) => void;
  /**
   * What theme to use for the table
   *
   * 'default' = Standard theme used for large full screen list views (e.g. contact list, leads list)
   * 'compact' = Used for smaller embedded tables used throughout classic (e.g. contracts, documents)
   */
  variant?: 'default' | 'compact';
  /**
   * 'light' = Used when background is dark
   * 'dark' = Used when background is light
   */
  colorScheme?: 'light' | 'dark';
  /**
   * Array of id's that can't be selected
   */
  disabledSelections?: (number | string)[];
  /**
   * Disable the ability to select all rows via removing the checkbox from the header
   */
  disableSelectAll?: boolean;
}

function RecordListTableInternal({
  name = 'all',
  items,
  columns,
  visibleColumns,
  setVisibleColumns = () => null,
  hasSelection = false,
  selection = { type: 'include', ids: [] },
  setSelection,
  orderBy,
  orderDir,
  setOrderBy = () => null,
  isLoading = false,
  EmptyView = () => null,
  LoadingView = () => null,
  allIds,
  onItemClick,
  variant = 'default',
  colorScheme = 'dark',
  disabledSelections,
  disableSelectAll = false
}: RecordListTableProps) {
  const s = useStyles(variant === 'compact' ? compactStyles : defaultStyles);
  const [previousRowIndex, setPreviousRowIndex] = useState<number>(0);
  const allItemIds = useMemo(() => allIds?.map(String), [allIds]);

  if (isLoading && !items?.length) {
    return <LoadingView />;
  }

  const columnIds = columns
    .map((column) => column.id)
    .filter((id) => (visibleColumns ? visibleColumns.includes(id) : true));

  const selectSingleItem = ({ currentRow, isSelected }) => {
    setSelection?.({
      type: selection.type,
      ids:
        (selection.type === 'include' && isSelected) ||
        (selection.type === 'exclude' && !isSelected)
          ? selection.ids.filter(
              (id) => id !== (currentRow.id || currentRow._id)
            )
          : selection.ids.concat(currentRow.id || currentRow._id)
    });
  };

  const getSelectedIds = ({ previousRowIndex, currentRowIndex }) => {
    const from = Math.min(previousRowIndex, currentRowIndex);
    const to = Math.max(previousRowIndex, currentRowIndex) + 1;

    return allItemIds?.slice(from, to) as string[];
  };

  const selectMultipleItems = ({ currentRow }) => {
    if (allItemIds) {
      const { type, ids } = selection;

      const currentRowIndex = allItemIds.indexOf(
        currentRow.id || currentRow._id
      );

      const selectedIds = getSelectedIds({ previousRowIndex, currentRowIndex });

      const isCheckedAlready =
        ids.indexOf(currentRow.id || currentRow._id) > -1;

      const newIds = isCheckedAlready
        ? difference(ids, selectedIds)
        : union(ids, selectedIds);

      setSelection?.({ type, ids: newIds });
    }
  };

  return (
    <table
      data-testid='RecordListScreen.Table'
      {...s('table', { light: colorScheme === 'light' })}
    >
      <thead>
        <tr>
          {hasSelection && (
            <th {...s('checkboxCell')}>
              {!disableSelectAll && (
                <label
                  {...s('checkboxLabel')}
                  data-testid='RecordListScreen.SelectAllCheckBox'
                >
                  <StylesProvider
                    components={overrides}
                    prioritiseParentStyles={false}
                  >
                    <Checkbox
                      id={name}
                      value={
                        selection?.type === 'exclude' && !selection?.ids?.length
                      }
                      onChange={() => {
                        setSelection?.(
                          selection.type === 'exclude'
                            ? {
                                type: 'include',
                                ids: [] as (number | string)[]
                              }
                            : {
                                type: 'exclude',
                                ids:
                                  disabledSelections ||
                                  ([] as (number | string)[])
                              }
                        );
                      }}
                      disabled={
                        !items?.length ||
                        disabledSelections?.length === items?.length
                      }
                    />
                  </StylesProvider>
                </label>
              )}
            </th>
          )}
          {columnIds?.map?.((columnId) => {
            const column = columns?.find?.((column) => column.id === columnId);
            if (!column) {
              return null;
            }
            const sortableField = getColumnSortField(column);
            const onClick =
              sortableField !== false
                ? () =>
                    setOrderBy(
                      sortableField,
                      orderBy === sortableField && orderDir === 'asc'
                        ? 'desc'
                        : 'asc'
                    )
                : undefined;
            return (
              <th
                key={columnId}
                {...s({ sortable: sortableField !== false })}
                onClick={onClick}
                style={{
                  width: column.width,
                  textAlign: column.rightAlign ? 'right' : 'left'
                }}
              >
                <span
                  {...s({
                    sortIcon: !!column.sortable,
                    sortIconActive: orderBy === column.sortable,
                    sortIconActiveDesc:
                      orderBy === sortableField && orderDir === 'desc'
                  })}
                >
                  {column?.label}
                </span>
              </th>
            );
          })}
        </tr>

        <Box {...s('columnButton')}>
          <ColumnsPopout
            columns={columns}
            visibleColumns={visibleColumns}
            setVisibleColumns={setVisibleColumns}
          />
        </Box>
      </thead>
      {!items?.length ? (
        <tbody>
          <td colSpan={hasSelection ? columnIds.length + 1 : columnIds.length}>
            <EmptyView />
          </td>
        </tbody>
      ) : (
        <tbody>
          {items?.map?.((item: any, index) => {
            const isSelected =
              selection?.type === 'include'
                ? selection?.ids?.includes?.(item.id || item._id)
                : !selection?.ids?.includes?.(item.id || item._id);
            const isDisabled = disabledSelections?.includes(
              item.id || item._id
            );

            const handleCheck = (event) => {
              const isShiftKeyPressed = event.nativeEvent.shiftKey;
              const hasSelection = selection.ids.length > 0;

              if (isShiftKeyPressed && hasSelection) {
                selectMultipleItems({ currentRow: item });
              } else {
                selectSingleItem({ currentRow: item, isSelected });
              }

              setPreviousRowIndex(index);
            };

            return (
              <tr
                {...s.with('row')({
                  cursor: onItemClick ? 'pointer' : 'auto',
                  opacity: isDisabled ? 0.5 : 1
                })}
                key={item?.id || index}
                onClick={() => onItemClick?.(item, allIds || [])}
              >
                {hasSelection && (
                  <td
                    {...s('checkboxCell')}
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                  >
                    <label
                      {...s('checkboxLabel')}
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                      data-testid='RecordListScreen.SelectCheckBox'
                    >
                      <StylesProvider
                        components={overrides}
                        prioritiseParentStyles={false}
                      >
                        <Checkbox
                          id={item.id || item._id}
                          value={isSelected}
                          onChange={handleCheck}
                          disabled={isDisabled}
                        />
                      </StylesProvider>
                    </label>
                  </td>
                )}
                {columnIds?.map?.((columnId) => {
                  const column = columns?.find?.(
                    (column) => column.id === columnId
                  );
                  if (!column) {
                    return null;
                  }

                  const Cell = column?.Cell || DefaultCell;

                  const value = isFunction(column.selector)
                    ? column.selector(item)
                    : get(item, column.selector || column.id);

                  const cellProps = isFunction(column.cellProps)
                    ? column.cellProps(item)
                    : column.cellProps;

                  return (
                    <Cell
                      key={columnId}
                      value={value}
                      emptyValue={column.emptyValue}
                      data={item}
                      width={column.width}
                      index={index}
                      {...(cellProps || {})}
                    />
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      )}
    </table>
  );
}

export const RecordListTable = memo<RecordListTableProps>(
  styled(defaultStyles)(RecordListTableInternal)
);

import { defineSlotRecipe } from '@chakra-ui/react';
import { menuAnatomy } from '@chakra-ui/react/anatomy';

export const menuSlotRecipe = defineSlotRecipe({
  className: 'chakra-menu',
  slots: menuAnatomy.keys(),
  base: {
    content: {
      outline: 0,
      bg: 'white',
      borderStyle: 'solid',
      borderColor: 'grey.500',
      borderWidth: '1px',
      boxShadow: 'xs',
      color: 'inherit',
      zIndex: 'dropdown',
      borderRadius: '0',
      overflow: 'hidden'
    },
    item: {
      textDecoration: 'none',
      color: 'inherit',
      textStyle: 'small',
      userSelect: 'none',
      width: '100%',
      display: 'flex',
      cursor: 'pointer',
      alignItems: 'center',
      textAlign: 'start',
      position: 'relative',
      flex: '0 0 auto',
      outline: 0,
      _disabled: {
        layerStyle: 'disabled'
      },
      _hover: {
        bg: 'blue.100'
      }
    },
    itemText: {
      flex: '1'
    },
    itemGroupLabel: {
      fontWeight: 'medium',
      textStyle: 'sm'
    },
    indicator: {
      display: 'inline-flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexShrink: '0'
    },
    itemCommand: {
      opacity: '0.6',
      textStyle: 'xs',
      ms: 'auto',
      ps: '4',
      letterSpacing: 'widest'
    },
    separator: {
      height: '1px',
      bg: 'bg.subtle',
      my: '1',
      mx: '-1'
    }
  },

  variants: {
    size: {
      md: {
        content: {
          minW: '100px',
          py: '1'
        },
        item: {
          gap: '2',
          textStyle: 'sm',
          py: '1',
          px: '2'
        }
      }
    }
  },

  defaultVariants: {
    size: 'md'
  }
});

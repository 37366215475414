import { Button, ButtonProps } from '@chakra-ui/react';
import Icon from 'shared/components/icon';

interface ActionButtonProps extends ButtonProps {
  iconType: string;
}

const ActionButton = ({ iconType, children, ...rest }: ActionButtonProps) => {
  return (
    <Button
      variant='text'
      textStyle={'buttonLabel'}
      color={'grey.900'}
      cursor={'pointer'}
      {...(rest as any)}
    >
      <Icon type={iconType} width={13} height={13} />
      {children}
    </Button>
  );
};

export default ActionButton;
